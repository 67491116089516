import { useAppDispatch, useAppSelector } from '../hooks';
import {
  getLanguageIsSelectable,
  getLanguageUserSelected,
  getTranslationsLoaded,
  languageActions,
} from './languageSlice';
import { usePassiveGetCaseDetail } from '../caseDetail/caseDetailHooks';
import { useCallback, useMemo } from 'react';
import { DEFAULT_LOCALES, DEFAULT_SELECTED_LOCALE } from '../../types/constants';
import { type LocaleDto } from '../../types/dtos';
import localizationService from '../../services/localizationService';
import { useTranslation } from 'react-i18next';
import windowService from '../../services/windowService';
import { useCountry } from '../country/countryHooks';
import { getLanguageSelectionTranslations } from '../countrySelection/countrySelectionSlice';

export const useLanguage: () => {
  locales: LocaleDto[];
  selectedLocale: LocaleDto;
  isUserSelectable: boolean;
  userSelected: boolean;
  selectLanguageCode: (localeCode: string, userSelect: boolean) => Promise<void>;
} = () => {
  const dispatch = useAppDispatch();
  const { i18n } = useTranslation();
  const { country } = useCountry();

  const selectedLanguageCode = i18n.language;
  const userSelected = useAppSelector(getLanguageUserSelected);
  const isSelectable = useAppSelector(getLanguageIsSelectable);
  const languageSelectionTranslations = useAppSelector(getLanguageSelectionTranslations);

  const [caseDetail, isFetchingCaseDetail] = usePassiveGetCaseDetail();

  const isUserSelectable = isSelectable && (userSelected || !isFetchingCaseDetail);

  const locales = useMemo(
    () =>
      caseDetail?.country.allowedLocales ??
      country?.allowedLocales ??
      languageSelectionTranslations?.locales ??
      DEFAULT_LOCALES,
    [caseDetail?.country.allowedLocales, country?.allowedLocales, languageSelectionTranslations?.locales],
  );

  const selectedLocale =
    locales.find(({ localeCode }) => localeCode === selectedLanguageCode) ?? DEFAULT_SELECTED_LOCALE;

  const selectLanguageCode = useCallback(
    async (languageCode: string, userSelect: boolean) => {
      await localizationService.changeLanguage(languageCode);
      dispatch(languageActions.selectLanguage({ userSelect }));
    },
    [dispatch],
  );

  return { locales, selectedLocale, isUserSelectable, userSelected, selectLanguageCode };
};

export const useFetchTranslations: () => {
  translationsLoaded: boolean;
  fetchCountryTranslations: () => void;
} = () => {
  const dispatch = useAppDispatch();

  const translationsLoaded = useAppSelector(getTranslationsLoaded);

  const fetchCountryTranslations = useCallback(() => {
    const countryCode = windowService.getCountrySearchParam();
    if (countryCode) {
      dispatch(languageActions.fetchTranslations({ countryCode }));
    }
  }, [dispatch]);

  return { translationsLoaded, fetchCountryTranslations };
};
