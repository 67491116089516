import React, { type FC } from 'react';
import { Trans } from 'react-i18next';

interface UploadErrorMessageProps {
  stepNumber: number;
  totalSteps: number;
}

const UploadErrorMessage: FC<UploadErrorMessageProps> = ({ stepNumber, totalSteps }) => {
  return (
    <span>
      <Trans i18nKey="Upload_failedHeader" values={{ stepNumber, totalSteps }} />
    </span>
  );
};

export default UploadErrorMessage;
