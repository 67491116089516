import React, { type FC } from 'react';

import MediaElement from '../common/MediaElement';
import { type MediaData, UploadStates } from '../../types/app';
import { DeleteOutlined } from '@ant-design/icons';
import { useDeleteImage } from '../../redux/upload/uploadHooks';
import { AnalyticsButton } from '../index';
import { ANALYTICS_EVENT_TYPES, MediaTypes } from '../../types/constants';
import fileStoreService from '../../services/fileStoreService';
import MediaPlaceholder from './MediaPlaceholder';
import UploadFailedPlaceholder from './UploadFailedPlaceholder';
import classNames from 'classnames';

import './DeletableMediaElement.scss';

interface DeletableMediaElementProps {
  stepMedia: MediaData[];
  mediaIndex: number;
  stepNumber: number;
}

const DeletableMediaElement: FC<DeletableMediaElementProps> = ({ stepMedia, mediaIndex, stepNumber }) => {
  const media = stepMedia[mediaIndex];
  const deleteImage = useDeleteImage(stepNumber, media);

  const fileInfo = fileStoreService.tryGetFile(media.fileStoreKey);

  return (
    <div className="DeletableMediaElement">
      {fileInfo && media.uploadState !== UploadStates.UploadFailed && (
        <MediaElement
          className="DeletableMediaElement__image"
          link={fileInfo.content}
          isVideo={media.mediaType === MediaTypes.VIDEO}
        />
      )}
      {!fileInfo && <MediaPlaceholder large />}
      {media.uploadState === UploadStates.UploadFailed && <UploadFailedPlaceholder />}
      <div className="DeletableMediaElement__count">{`${mediaIndex + 1}/${stepMedia.length}`}</div>
      <AnalyticsButton
        className={classNames('DeletableMediaElement__delete', {
          'DeletableMediaElement__delete--error': media.uploadState === UploadStates.UploadFailed,
        })}
        eventType={ANALYTICS_EVENT_TYPES.RemoveMedia}
        analyticsValue={media.mediaType === MediaTypes.VIDEO ? 'video' : 'photo'}
        type="primary"
        danger={media.uploadState === UploadStates.UploadFailed}
        icon={<DeleteOutlined />}
        shape="circle"
        loading={media.uploadState === UploadStates.Uploading || media.uploadState === UploadStates.PreUploading}
        onClick={deleteImage}
      />
    </div>
  );
};

export default DeletableMediaElement;
