import React, { type FC } from 'react';

import './UploadFailedPlaceholder.scss';
import MediaPlaceholder from './MediaPlaceholder';
import UploadErrorIcon from '../common/UploadErrorIcon';
import { Trans } from 'react-i18next';

const UploadFailedPlaceholder: FC = () => (
  <div className="UploadFailedPlaceholder">
    <MediaPlaceholder medium borderless />
    <div className="UploadFailedPlaceholder__message">
      <UploadErrorIcon />
      <Trans i18nKey="Upload_uploadFailed" />
    </div>
  </div>
);

export default UploadFailedPlaceholder;
