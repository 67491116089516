export const APPLICATION_DEFAULT_TITLE = 'Product Complaint Photo App'; // Used only before translations are loaded
export const CAROUSEL_MAX_SCREEN_WIDTH = 540; // Keep in sync with scss variable $carouselMaxWidth

export const MAX_UPLOAD_PHOTO_LIMIT = 10;
export const MAX_UPLOAD_VIDEO_LIMIT = 2;

// Minimum delay between fetching of two different media files to prevent network errors on API platform.
export const MEDIA_PREFETCH_DELAY = 50; // ms
export const MEDIA_RE_FETCH_DELAY = 500; // ms
export const MEDIA_RE_FETCH_RETRY_COUNT = 5; // ms

export enum MediaTypes {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export const ACCEPT_IMAGE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.jif',
  '.jfif',
  '.pjp',
  '.pjpeg',
  '.png',
  '.heic',
  '.heif',
];
export const ACCEPT_VIDEO_EXTENSIONS = ['.mp4', '.mov'];

export const QR_SEARCH_PARAM_NAME = 'qr';
export const LOCALIZATION_SEARCH_PARAM_NAME = 'loc';
export const QR_SEARCH_PARAM_VALUE = 'true';

export const CONFIRMATION_PAGE_ID = 'confirmationPage';

export const COUNTRY_SEARCH_PARAM_NAMES = ['ct', 'Ct', 'CT']; // user may enter it manually in the URL. Lets add also upper case options

export const DEFAULT_LOCALES = [
  {
    localeCode: 'pt-BR',
    localeDisplayName: 'Português',
  },
  {
    localeCode: 'en-US',
    localeDisplayName: 'English',
  },
];

export const DEFAULT_SELECTED_LOCALE = DEFAULT_LOCALES[0];

// locale that is used when sending translated codes to server (e.g. issue types)
export const SERVER_ENGLISH_LOCALE = 'en-US';

export enum ANALYTICS_EVENT_TYPES {
  ApplicationOpen = 'Application Open',
  CountrySelectedByLocation = 'Country Selected by Location',
  CountrySelectedByRegion = 'Country Selected by Region',
  LanguageChanged = 'Language Changed',
  SwitchDeviceModal = 'Switch Device Modal',
  CaseIdEntered = 'Case ID entered',
  TermsConditionAgree = 'Terms & condition - agree',
  TermsConditionRead = 'Terms & condition - read',
  HelpDialog = 'Help dialog',
  HelpDialogStep = 'Help dialog step',
  IssuesListOpen = 'Issues List Open',
  IssuesNameChanged = 'Issues Name Changed',
  SendHelpRequest = 'Send help request',
  StartGuidedInstruction = 'Start guided instruction',
  Next = 'Next',
  Back = 'Back',
  ViewExamplePhotoFull = 'View example photo full',
  AddPhoto = 'Add photo',
  AddVideo = 'Add video',
  RemoveMedia = 'Remove media',
  MediaLimitReached = 'Media limit reached',
  InvalidFile = 'Invalid File',
  Submit = 'Submit',
  BackToHome = 'Back to home',
  CaseNotFound = 'Case not found',
  FAQHelpful = 'FAQ helpful',

  FetchError = 'Fetch Error', // Not a user action
}

export enum APP_PAGES {
  countryPage = 'countryPage',
  landingPage = 'landingPage',
  caseNotFound = 'caseNotFound',
  ticketInformation = 'ticketInformation',
  termsAndConditions = 'termsAndConditions',
  instructions = 'instructions',
  multipleUnitsInfo = 'multipleUnitsInfo',
  upload = 'upload',
  submit = 'submit',
  confirmation = 'confirmation',
}
