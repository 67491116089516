import { useAppDispatch, useAppParams, useAppRouter, useAppSelector } from '../hooks';
import {
  caseDetailActions,
  getCaseDetail,
  getIsFetching,
  getIsSubmissionFinished,
  getIsSubmissionStarted,
  getIsSubmissionStarting,
} from './caseDetailSlice';
import { useCallback, useEffect } from 'react';
import { type CasePublicDto, type StepInstructionPublicDto } from '../../types/dtos';
import { useNavigate } from 'react-router';
import { routes } from '../../router';
import { useLanguage } from '../language/languageHooks';

export const useCaseDetailFromQuery: () => [CasePublicDto | undefined, boolean] = () => {
  const { caseId } = useAppParams();
  const [caseDetail, fetchCaseDetail, isFetching] = useCaseDetail();

  useEffect(() => {
    if (caseId && !isFetching && (!caseDetail || caseDetail.dpocCaseId !== caseId)) {
      fetchCaseDetail(caseId);
    }
  }, [caseDetail, caseId, fetchCaseDetail, isFetching]);

  return [caseDetail, isFetching];
};

export const usePassiveGetCaseDetail: () => [CasePublicDto | undefined, boolean] = () => {
  const caseDetail = useAppSelector(getCaseDetail);
  const isFetching = useAppSelector(getIsFetching);

  return [caseDetail, isFetching];
};

export const useCaseDetail: () => [
  CasePublicDto | undefined,
  (dpocCaseId: string, termsAgreed?: boolean) => void,
  boolean,
] = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const caseDetail = useAppSelector(getCaseDetail);
  const isFetching = useAppSelector(getIsFetching);

  const fetchCaseDetail = useCallback(
    (dpocCaseId: string) => {
      if (!isFetching) {
        dispatch(caseDetailActions.fetchDetail({ dpocCaseId, navigate }));
      }
    },
    [dispatch, isFetching, navigate],
  );

  return [caseDetail, fetchCaseDetail, isFetching];
};

export const useStartGuidedInstructions: () => {
  isSubmissionStarting: boolean;
  startSubmission: (termsAgreed: boolean) => void;
} = () => {
  const dispatch = useAppDispatch();
  const { caseId } = useAppParams();
  const navigate = useNavigate();
  const isSubmissionStarting = useAppSelector(getIsSubmissionStarting);

  const startSubmission = useCallback(
    (termsAgreed: boolean) => {
      if (termsAgreed && caseId) {
        dispatch(caseDetailActions.startSubmission({ termsAgreed, navigate }));
      }
    },
    [caseId, dispatch, navigate],
  );

  return { isSubmissionStarting, startSubmission };
};

export const useStepInstructions: (stepNumber: number) => StepInstructionPublicDto | undefined = (stepNumber) => {
  const [caseDetail] = usePassiveGetCaseDetail();
  const { selectedLocale } = useLanguage();

  return caseDetail?.submission.stepInstructions[selectedLocale.localeCode][stepNumber - 1];
};

export const useReset: () => () => void = () => {
  const dispatch = useAppDispatch();
  return useCallback(() => {
    dispatch(caseDetailActions.reset());
  }, [dispatch]);
};

export const useEnsureRequireSubmissionStarted: () => void = () => {
  const navigate = useNavigate();
  const { caseId } = useAppParams();
  const { activePage } = useAppRouter();

  const submissionStarted = useAppSelector(getIsSubmissionStarted);

  useEffect(() => {
    if (activePage.requiresStartedSubmission && !submissionStarted) {
      if (caseId) {
        navigate(routes.ticketInformation(caseId));
      } else {
        navigate(routes.landingPage());
      }
    }
  }, [caseId, navigate, activePage?.requiresStartedSubmission, submissionStarted]);
};

export const useEnsureSubmissionNotFinished: () => void = () => {
  const navigate = useNavigate();
  const { activePage } = useAppRouter();

  const submissionFinished = useAppSelector(getIsSubmissionFinished);

  useEffect(() => {
    if (activePage.requiresNotFinishedSubmission && submissionFinished) {
      navigate(routes.landingPage());
    }
  }, [navigate, activePage.requiresNotFinishedSubmission, submissionFinished]);
};
