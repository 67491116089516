import React, { type FC } from 'react';
import { type CasePublicDto } from '../../types/dtos';

import './DpocLink.scss';

interface DpocLinkProps {
  caseDetail?: CasePublicDto;
  dpocEmail: string;
}

const createMailToLink = (email: string, dpocCaseId?: string): string =>
  dpocCaseId
    ? `mailto:${email}?subject=Product Complaint Photo App Defect ID: ${dpocCaseId}`
    : `mailto:${email}?subject=Product Complaint Photo App`;

const DpocLink: FC<DpocLinkProps> = ({ caseDetail, dpocEmail }) => (
  <a className="DpocLink" href={createMailToLink(dpocEmail, caseDetail?.dpocCaseId)}>
    {dpocEmail}
  </a>
);

export default DpocLink;
