import { type CasePublicDto, type StepInstructionPublicDto, type SubmissionPublicDto } from '../types/dtos';
import { type MediaData, type MediaLink } from '../types/app';
import { MediaTypes } from '../types/constants';

const getMediaKey = (
  submission: SubmissionPublicDto,
  stepInstruction: StepInstructionPublicDto,
  isVideo: boolean,
  index: number,
): string => `${submission.id}-${stepInstruction.id}-${isVideo ? 'V' : 'P'}-${index.toString()}`;

const createMediaLink = (
  src: string,
  caseDetailDto: CasePublicDto,
  instruction: StepInstructionPublicDto,
  isVideo: boolean,
  index: number,
): MediaLink => ({
  src,
  mediaKey: getMediaKey(caseDetailDto.submission, instruction, isVideo, index),
});

const splitMedia = (stepMedia: MediaData[]): { photos: MediaData[]; videos: MediaData[] } => {
  return {
    photos: stepMedia.filter(({ mediaType }) => mediaType === MediaTypes.IMAGE),
    videos: stepMedia.filter(({ mediaType }) => mediaType === MediaTypes.VIDEO),
  };
};

const mediaService = {
  getMediaKey,
  createMediaLink,
  splitMedia,
};

export default mediaService;
