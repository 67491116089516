import {
  caseDetailActions,
  getCaseDetail,
  type FetchCaseDetailPayload,
  type StartGuidedInstructionsPayload,
} from './caseDetailSlice';
import i18n from '../../i18n';
import { type CasePublicDto, type StepInstructionPublicDto } from '../../types/dtos';
import { type ForkEffect, type Effect, takeEvery, put, call, select, all } from 'redux-saga/effects';
import { type PayloadAction } from '@reduxjs/toolkit';
import fetchService, { type FetchError } from '../../services/fetchService';
import { getLanguageUserSelected } from '../language/languageSlice';
import { routes } from '../../router';
import localizationService from '../../services/localizationService';
import { mediaCacheActions } from '../mediaCache/mediaCacheSlice';
import mediaService from '../../services/mediaService';
import { type MediaLink } from '../../types/app';
import { functionDoFetchTranslations } from '../language/languageSaga';

const createMediaLinks = (
  urls: string[],
  caseDetailDto: CasePublicDto,
  instruction: StepInstructionPublicDto,
  isVideo: boolean,
): MediaLink[] =>
  urls.map((src, index) => mediaService.createMediaLink(src, caseDetailDto, instruction, isVideo, index));

function* createNewSubmission(dpocCaseId: string): Generator<Effect, CasePublicDto, any> {
  const caseDetailDto: CasePublicDto = yield call(fetchService.createNewSubmission, dpocCaseId);

  // Call this 'select' and 'languageCode' get after `fetchService.createNewSubmission` because that is async operation and the values can be changes meanwhile.
  const userSelected = yield select(getLanguageUserSelected);
  const languageCode = i18n.language;
  if (!userSelected || !caseDetailDto.country.allowedLocales.find(({ localeCode }) => localeCode === languageCode)) {
    yield call(
      localizationService.changeLanguage,
      caseDetailDto.defaultLocaleCode ?? caseDetailDto.country.defaultLocaleCode,
    );
  }

  const sampleLinks = Object.values(caseDetailDto.submission.stepInstructions).reduce<MediaLink[]>(
    (acc, localeInstructions) => {
      localeInstructions.forEach((instruction) => {
        acc.push(...createMediaLinks(instruction.samplePhotoLinks, caseDetailDto, instruction, false));
        acc.push(...createMediaLinks(instruction.sampleVideoLinks, caseDetailDto, instruction, true));
      });
      return acc;
    },
    [],
  );
  const bestPracticesLinks = Object.values(caseDetailDto.submission.bestPractices).reduce<MediaLink[]>(
    (acc, localeBestPractices) => {
      localeBestPractices.forEach(({ instructions }) => {
        instructions.forEach(({ mediaLinks }) => {
          const links = mediaLinks.map(({ url, s3key }) => ({ src: url, mediaKey: s3key }));
          acc.push(...links);
        });
      });
      return acc;
    },
    [],
  );

  const uniqueMediaToPrefetch = [...bestPracticesLinks, ...sampleLinks].reduce<MediaLink[]>((acc, mediaLink) => {
    if (!acc.find(({ mediaKey }) => mediaKey === mediaLink.mediaKey)) {
      acc.push(mediaLink);
    }
    return acc;
  }, []);

  // Force to prefetch all sample media and best practices photos, so user may not see loader there.
  yield all(
    uniqueMediaToPrefetch.map((mediaLink) =>
      put(mediaCacheActions.request({ src: mediaLink.src, mediaKey: mediaLink.mediaKey })),
    ),
  );

  return caseDetailDto;
}

function* watchFetchDetail(action: PayloadAction<FetchCaseDetailPayload>): Generator<Effect, void, any> {
  const { dpocCaseId, navigate } = action.payload;
  try {
    const [caseDetailDto]: [CasePublicDto, any] = yield all([
      call(createNewSubmission, dpocCaseId),
      call(functionDoFetchTranslations, undefined, dpocCaseId),
    ]);

    yield put(caseDetailActions.fetchDetailFinished(caseDetailDto));
    navigate(routes.ticketInformation(caseDetailDto.dpocCaseId));
  } catch (e) {
    const fetchError = e as FetchError;
    if (fetchError.status === 404 || fetchError.status === 410) {
      navigate(routes.caseNotFound());
    }
    yield put(caseDetailActions.fetchDetailFailed());
  }
}

function* watchStartSubmission(action: PayloadAction<StartGuidedInstructionsPayload>): Generator<Effect, void, any> {
  try {
    const { termsAgreed, navigate } = action.payload;
    const caseDetail: CasePublicDto = yield select(getCaseDetail);

    if (termsAgreed) {
      yield call(fetchService.startSubmission, caseDetail.dpocCaseId, caseDetail.submission.id, i18n.language);
    }
    navigate(routes.instructions(caseDetail.dpocCaseId));
    yield put(caseDetailActions.startSubmissionFinished());
  } catch {
    yield put(caseDetailActions.startSubmissionFailed());
  }
}

export function* watchCaseDetailSagas(): Generator<ForkEffect, void> {
  yield takeEvery(caseDetailActions.fetchDetail, watchFetchDetail);
  yield takeEvery(caseDetailActions.startSubmission, watchStartSubmission);
}

const caseDetailSagas = watchCaseDetailSagas;

export default caseDetailSagas;
