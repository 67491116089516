import React, { type FC } from 'react';
import { useGetAllMedia } from '../../redux/upload/uploadHooks';
import { MediaTypes } from '../../types/constants';
import UploadedMediaSummary from '../Upload/UploadedMediaSummary';
import mediaService from '../../services/mediaService';

const SubmittedMedia: FC = () => {
  const stepMedia = useGetAllMedia();
  const { photos, videos } = mediaService.splitMedia(stepMedia);

  return (
    <>
      {!!photos.length && <UploadedMediaSummary mediaType={MediaTypes.IMAGE} stepMedia={photos} isMandatory={false} />}
      {!!videos.length && <UploadedMediaSummary mediaType={MediaTypes.VIDEO} stepMedia={videos} isMandatory={false} />}
    </>
  );
};

export default SubmittedMedia;
