import React, { type FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { type MediaData } from '../../types/app';
import { MediaTypes } from '../../types/constants';

import './UploadedMediaSummaryHeader.scss';
import mediaService from '../../services/mediaService';

interface UploadedMediaSummaryHeaderProps {
  mediaType: MediaTypes;
  stepMedia: MediaData[];
  isMandatory: boolean;
  large?: boolean;
}

const UploadedMediaSummaryHeader: FC<UploadedMediaSummaryHeaderProps> = ({
  mediaType,
  stepMedia,
  isMandatory,
  large,
}) => {
  const { t } = useTranslation();
  const { photos, videos } = mediaService.splitMedia(stepMedia);

  const isEmpty = !stepMedia.length;

  const titleText = mediaType === MediaTypes.IMAGE ? t('Upload_yourPhotos') : t('Upload_yourVideos');

  const countText =
    !!photos.length || !!videos.length
      ? mediaType === MediaTypes.IMAGE
        ? t('Upload_photosCount', { count: photos.length })
        : t('Upload_videosCount', { count: videos.length })
      : undefined;

  const emptyText = isEmpty
    ? mediaType === MediaTypes.IMAGE
      ? isMandatory
        ? t('Upload_uploadPhotosToProceed')
        : t('Upload_emptyPhotos')
      : isMandatory
        ? t('Upload_uploadVideosToProceed')
        : t('Upload_emptyVideos')
    : undefined;

  return (
    <div
      className={classNames('UploadedMediaSummaryHeader', {
        'UploadedMediaSummaryHeader--large': large,
        'UploadedMediaSummaryHeader--empty': !!emptyText,
      })}
    >
      <div className="UploadedMediaSummaryHeader__titleRow">
        <div
          className={classNames('UploadedMediaSummaryHeader__titleRow__title', {
            'UploadedMediaSummaryHeader__titleRow__title--empty': !!emptyText,
          })}
        >
          {titleText}
        </div>
        {countText && <div className="UploadedMediaSummaryHeader__titleRow__count">{countText}</div>}
      </div>
      {emptyText && <div className="UploadedMediaSummaryHeader__empty">{emptyText}</div>}
    </div>
  );
};

export default UploadedMediaSummaryHeader;
