import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse, Divider } from 'antd';

import { type CountryLocaleDto, type LocaleDto } from '../../types/dtos';
import { AnalyticsButtonLink } from '../index';
import { type Region } from '../../types/app';
import { ANALYTICS_EVENT_TYPES } from '../../types/constants';

import './RegionCountrySelector.scss';
import windowService from '../../services/windowService';
import { useHref } from 'react-router';
import { routes } from '../../router';

const { Panel } = Collapse;

interface RegionCountrySelectorProps {
  regions: Region[];
  onSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
}

interface RegionHeaderProps {
  regionTranslationKey: string;
}

const RegionHeader: FC<RegionHeaderProps> = ({ regionTranslationKey }) => {
  const { t } = useTranslation();

  return <span className="RegionHeader">{t(regionTranslationKey)}</span>;
};

interface RegionContentProps {
  region: Region;
  onSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
}

interface RegionCountryProps {
  country: CountryLocaleDto;
  onSelectLocale: (country: CountryLocaleDto, locale: LocaleDto) => void;
}

const RegionCountry: FC<RegionCountryProps> = ({ country, onSelectLocale }) => {
  const { t } = useTranslation();
  const baseHref = useHref(routes.landingPage());

  const handleSelectLocale = (locale: LocaleDto): void => {
    const isSwitchingDomain = windowService.switchDomainByCountry(country, locale, baseHref);
    if (isSwitchingDomain) {
      return;
    }
    onSelectLocale(country, locale);
  };

  return (
    <div className="RegionCountry">
      <div className="RegionCountry__name">{t(country.countryTranslationKey)}</div>
      <div className="RegionCountry__locales">
        {country.allowedLocales.map((locale) => (
          <AnalyticsButtonLink
            eventType={ANALYTICS_EVENT_TYPES.CountrySelectedByRegion}
            analyticsValue={JSON.stringify({ countryCode: country.countryCode, localeCode: locale.localeCode })}
            key={locale.localeCode}
            className="RegionCountry__locales__locale"
            onClick={() => {
              handleSelectLocale(locale);
            }}
          >
            {locale.localeDisplayName}
          </AnalyticsButtonLink>
        ))}
      </div>
    </div>
  );
};

const RegionContent: FC<RegionContentProps> = ({ region, onSelectLocale }) => {
  const { t } = useTranslation();
  const sortedCountries = useMemo(
    () => region.countries.sort((a, b) => t(a.countryTranslationKey).localeCompare(t(b.countryTranslationKey))),
    [region.countries, t]
  );

  return (
    <div className="RegionContent">
      {sortedCountries.map((country) => (
        <RegionCountry key={country.countryCode} country={country} onSelectLocale={onSelectLocale} />
      ))}
    </div>
  );
};

const RegionCountrySelector: FC<RegionCountrySelectorProps> = ({ regions, onSelectLocale }) => {
  return (
    <div className="RegionCountrySelector">
      <Collapse ghost className="RegionCountrySelector__regions">
        {regions.map((region) => (
          <Panel
            className="RegionCountrySelector__regions__region"
            key={region.regionTranslationKey}
            header={<RegionHeader regionTranslationKey={region.regionTranslationKey} />}
          >
            <RegionContent key={region.regionTranslationKey} region={region} onSelectLocale={onSelectLocale} />
            <Divider className="RegionCountrySelector__regions__region__divider" />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default RegionCountrySelector;
