import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type RootState } from '../store';
import { caseDetailActions } from '../caseDetail/caseDetailSlice';
import { countryActions } from '../country/countrySlice';
import { countrySelectionActions } from '../countrySelection/countrySelectionSlice';

export interface LanguageSlice {
  translationsLoaded: boolean;
  userSelected: boolean;
  isSelectable: boolean;
}

export const initialState: LanguageSlice = {
  translationsLoaded: false,
  userSelected: false,
  isSelectable: true,
};

export const getTranslationsLoaded = (state: RootState): boolean => state.language.translationsLoaded;
export const getLanguageUserSelected = (state: RootState): boolean => state.language.userSelected;
export const getLanguageIsSelectable = (state: RootState): boolean => state.language.isSelectable;

export interface FetchTranslationsPayload {
  countryCode: string;
}

export interface SelectLanguagePayload {
  userSelect: boolean;
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    fetchTranslations: (state, _action: PayloadAction<FetchTranslationsPayload>) => {
      state.translationsLoaded = false;
    },
    fetchTranslationsFinished: (state) => {
      state.translationsLoaded = true;
    },
    fetchTranslationsFailed: () => {},
    selectLanguage: (state, { payload: { userSelect } }: PayloadAction<SelectLanguagePayload>) => {
      state.userSelected = userSelect;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(caseDetailActions.startSubmissionFinished, (state) => {
      state.isSelectable = false;
    });
    builder.addCase(caseDetailActions.reset, (state) => {
      state.isSelectable = true;
    });
    builder.addCase(countryActions.fetchCountry, (state) => {
      state.isSelectable = false;
    });
    builder.addCase(countryActions.fetchCountryFinished, (state) => {
      state.isSelectable = true;
    });
    builder.addCase(countrySelectionActions.selectLocale, (state) => {
      state.userSelected = true;
    });
    builder.addCase(countrySelectionActions.selectLocaleFinished, (state) => {
      state.translationsLoaded = true;
    });
  },
});

export const { actions: languageActions, reducer: languageReducer } = languageSlice;
