const fileStore: Record<string, { file: File; content: string } | null> = {};
let storedFileIndex = 0; // Does not change on file delete

const createNewKey = (): string => {
  return `${storedFileIndex++}`;
};
const storeFile = (file: File, content: string, fileStoreKey: string): void => {
  fileStore[fileStoreKey] = {
    file,
    content,
  };
};

const getFile = (fileStoreKey: string): { file: File; content: string } => {
  const fileInfo = fileStoreService.tryGetFile(fileStoreKey);

  if (!fileInfo) {
    throw new Error(`File with key '${fileStoreKey}' was not found in the store.`);
  }

  return fileInfo;
};

const tryGetFile = (fileStoreKey: string): { file: File; content: string } | null => {
  return fileStore[fileStoreKey];
};

const releaseFile = (fileStoreKey: string): void => {
  fileStore[fileStoreKey] = null;
};

const fileStoreService = { createNewKey, storeFile, getFile, tryGetFile, releaseFile };

export default fileStoreService;
