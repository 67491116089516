import React, { type FC } from 'react';

import { type CasePublicDto } from '../../types/dtos';
import Section from './Section';
import { Descriptions } from 'antd';
import './TicketInformation.scss';
import formatService from '../../services/formatService';
import { useLanguage } from '../../redux/language/languageHooks';
import { useTranslation } from 'react-i18next';

interface TicketInformationProps {
  caseDetail: CasePublicDto;
}

const TicketInformation: FC<TicketInformationProps> = ({ caseDetail }) => {
  const { t } = useTranslation();
  const { selectedLocale } = useLanguage();

  if (!caseDetail.defectTypes[selectedLocale.localeCode]) {
    console.error(
      `Missing "defectTypes" on "caseDetail" [DpocCaseId='${caseDetail.dpocCaseId}'] for "localeCode"='${selectedLocale.localeCode}'.`,
    );
  }

  return (
    <div className="TicketInformation">
      <Section title={t('TicketInformation_ticketInformation')}>
        <Descriptions
          className="TicketInformation__descriptions"
          layout="vertical"
          size="default"
          column={2}
          colon={false}
        >
          <Descriptions.Item label={t('TicketInformation_caseId')}>{caseDetail.dpocCaseId}</Descriptions.Item>
          <Descriptions.Item label={t('TicketInformation_reportedDate')}>
            {formatService.toDisplayDate(caseDetail.createdAt)}
          </Descriptions.Item>
        </Descriptions>
      </Section>
      <Section title={t('TicketInformation_defectInformation')}>
        <Descriptions
          className="TicketInformation__descriptions"
          layout="vertical"
          size="default"
          column={{ md: 2, sm: 1, xs: 1 }}
          colon={false}
        >
          <Descriptions.Item label={t('TicketInformation_reportedDefectType')}>
            {caseDetail.defectTypes[selectedLocale.localeCode].join(', ')}
          </Descriptions.Item>
          {caseDetail.multipleUnits && (
            <Descriptions.Item label={t('TicketInformation_multipleUnits')}>
              {t('TicketInformation_multipleUnitsYes')}
            </Descriptions.Item>
          )}
        </Descriptions>
      </Section>
    </div>
  );
};

export default TicketInformation;
