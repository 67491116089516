import {
  // eslint-disable-next-line no-restricted-imports
  useDispatch,
  type TypedUseSelectorHook,
  // eslint-disable-next-line no-restricted-imports
  useSelector,
} from 'react-redux';
import { type AppDispatch, type RootState } from './store';
import { usePassiveGetCaseDetail } from './caseDetail/caseDetailHooks';
// eslint-disable-next-line no-restricted-imports
import { useHref, useLocation, useParams } from 'react-router';
import { useCallback, useState } from 'react';
import { relativeRoutes, routes } from '../router';
import { useGetAllMedia } from './upload/uploadHooks';
// eslint-disable-next-line no-restricted-imports
import { matchPath, useSearchParams } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  type APP_PAGES,
  COUNTRY_SEARCH_PARAM_NAMES,
  DEFAULT_LOCALES,
  LOCALIZATION_SEARCH_PARAM_NAME,
  QR_SEARCH_PARAM_NAME,
  QR_SEARCH_PARAM_VALUE,
} from '../types/constants';
import { type APPLICATION_PAGE_INFO, APPLICATION_PAGES } from '../types/app';
import { useLanguage } from './language/languageHooks';
import { useAnalytics } from './analytics/analyticsHooks';
import { useCountry } from './country/countryHooks';
import windowService from '../services/windowService';
import localizationService from '../services/localizationService';

export const useAppParams: () => { caseId: string | undefined; stepNumber: number | undefined } = () => {
  const { stepNumber: stepNumberString, caseId: caseIdString } = useParams();
  try {
    const stepNumberParsed = stepNumberString && Number.parseInt(stepNumberString);
    const stepNumber = stepNumberParsed === '' ? undefined : stepNumberParsed;
    const caseId = caseIdString && decodeURIComponent(caseIdString);
    return { stepNumber, caseId };
  } catch (e) {
    // We need try catch here, because the code is used in ErrorBoundary, and we have to be sure, it will not fail.
    console.error(e);
    return { stepNumber: undefined, caseId: undefined };
  }
};

export const useApplicationInit: () => () => void = () => {
  const { log } = useAnalytics();
  const { selectLanguageCode } = useLanguage();
  const [searchParams] = useSearchParams();

  return useCallback(() => {
    const qrValue = searchParams.get(QR_SEARCH_PARAM_NAME);
    const locale = searchParams.get(LOCALIZATION_SEARCH_PARAM_NAME) ?? undefined;
    const qrAccessed = qrValue === QR_SEARCH_PARAM_VALUE;
    const countryCode = windowService.getCountrySearchParam();

    log(ANALYTICS_EVENT_TYPES.ApplicationOpen, qrAccessed ? 'QR' : (countryCode ?? undefined));
    if (locale) {
      selectLanguageCode(locale, true).catch((e) => {
        console.error(e);
      });
    } else {
      const preferredUserLocaleCode = localizationService.getPreferredLocaleCode(DEFAULT_LOCALES);
      if (preferredUserLocaleCode) {
        selectLanguageCode(preferredUserLocaleCode, false).catch((e) => {
          console.error(e);
        });
      }
    }
  }, [log, searchParams, selectLanguageCode]);
};

export const useLinkCreator: () => { displayHref: string; qrHref: string } = () => {
  const { caseId } = useAppParams();
  const [caseDetail] = usePassiveGetCaseDetail();
  const { selectedLocale, userSelected } = useLanguage();
  const { origin, search } = window.location;
  const { country } = useCountry();

  let newSearch = search;
  const params = new URLSearchParams();
  params.append(QR_SEARCH_PARAM_NAME, QR_SEARCH_PARAM_VALUE);
  if (userSelected) {
    params.append(LOCALIZATION_SEARCH_PARAM_NAME, selectedLocale.localeCode);
  }

  const countrySearchParam = windowService.getCountrySearchParam();
  const countryCode = caseDetail?.country.countryCode ?? country?.countryCode;
  if (!countrySearchParam && countryCode) {
    const newSearchParams = new URLSearchParams(search);
    newSearchParams.append(COUNTRY_SEARCH_PARAM_NAMES[0], countryCode);
    newSearch = `?${newSearchParams.toString()}`;
  }
  const basePath = caseId
    ? routes.ticketInformation(caseId)
    : (country ?? countrySearchParam)
      ? routes.landingPage()
      : routes.countryPage();

  const baseHref = useHref(basePath);

  const qrHref = caseId
    ? `${origin}${baseHref}?${params.toString()}`
    : `${origin}${newSearch}${baseHref}?${params.toString()}`;
  const displayHref = `${origin}${newSearch}`;

  return { displayHref, qrHref };
};

export const useToggle = (defaultValue: boolean): [boolean, () => void] => {
  const [state, setState] = useState(defaultValue);
  const toggle = useCallback(() => {
    setState((prev) => !prev);
  }, []);

  return [state, toggle];
};

export const useCanSwitchDevice: () => boolean = () => {
  const { activePage } = useAppRouter();

  const [_caseDetail, isFetchingCaseDetail] = usePassiveGetCaseDetail();

  const stepMedia = useGetAllMedia();

  const pageSupportsSwitchDevice = activePage.canSwitchDevice;

  return pageSupportsSwitchDevice && !stepMedia.length && !isFetchingCaseDetail;
};

export const useAppRouter: () => { activePage: APPLICATION_PAGE_INFO } = () => {
  const { pathname } = useLocation();

  const activePageKey = Object.entries(relativeRoutes).find(([_routeName, routePath]) =>
    matchPath(routePath, pathname),
  )?.[0] as APP_PAGES;

  return { activePage: APPLICATION_PAGES[activePageKey] };
};

export const useMerckDomain: () => { isMerckDomain: boolean } = () => {
  const [caseDetail] = usePassiveGetCaseDetail();
  const { country } = useCountry();

  const isMerckDomain = caseDetail
    ? caseDetail.country.merckCountry
    : country
      ? country.merckCountry
      : windowService.getIsMerckDomain();

  return { isMerckDomain };
};

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
