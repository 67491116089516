import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

const CaseIdRequiredError: FC = () => {
  const { t } = useTranslation();

  return <span>{t('CaseEnter_caseIdRequired')}</span>;
};

export default CaseIdRequiredError;
