import { notification } from 'antd';

import urlsService from './urlsService';
import sessionService from './sessionService';
import {
  type AnalyticsDto,
  type CasePublicDto,
  type CountryLocaleDto,
  type FaqWithTranslationsDto,
  type LanguageSelectionTranslationsDto,
  type MediaPutTuple,
  type PublicTranslationDto,
} from '../types/dtos';
import { APPLICATION_CONFIGURATION } from '../types/applicationConfiguration';
import {
  ANALYTICS_EVENT_TYPES,
  MEDIA_RE_FETCH_DELAY,
  MEDIA_RE_FETCH_RETRY_COUNT,
  type MediaTypes,
} from '../types/constants';

export class FetchError extends Error {
  status: number;
  logged: boolean;

  constructor(msg: string, status: number, logged: boolean) {
    super(msg);
    this.status = status;
    this.logged = logged;
  }
}

interface ErrorResponse {
  errorMessage?: string;
  additionalDetails?: Record<string, string>;
}

const logError = async (url: string, error: any): Promise<void> => {
  // Do not log analytics errors to prevent infinite loop
  if (url !== urlsService.analytics()) {
    const value = JSON.stringify({ url, ...error });
    const analytics: AnalyticsDto = {
      sessionId: sessionService.getSessionId(),
      eventType: ANALYTICS_EVENT_TYPES.FetchError,
      value,
    };
    await logAnalytics(analytics);
  }
};

const handleCatchError = async (ex: any, url: string, additionalLogInfo?: any): Promise<void> => {
  if (!ex.logged) {
    await logError(url, {
      message: ex?.toString() || 'Unknown fetch error.',
      ...additionalLogInfo,
    });
  }
};

const handleError = async (
  response: Response,
  url: string,
  init?: RequestInit,
  additionalLogInfo?: any,
): Promise<string> =>
  await response
    .text()
    .then((errorMessage): ErrorResponse => {
      try {
        // usually {errorMessage: ...} from backend, but can be HTML from server/framework unhandled exceptions
        return JSON.parse(errorMessage);
      } catch (err) {
        console.error(url, err);

        return { errorMessage };
      }
    })
    .then(async (error) => {
      const errorMessage = error.errorMessage != null ? error.errorMessage : JSON.stringify(error, null, 2);
      const method = init?.method ?? 'GET';
      const { status, statusText } = response;
      const intro = `${status} ${statusText}: ${method} ${url}`;
      if (error.additionalDetails) {
        console.error(`${intro} additional details\n`, error.additionalDetails);
      }

      if (response.status !== 404 && response.status !== 410) {
        notification.error({
          message: 'Network Error',
          description: errorMessage,
          duration: 0,
        });
      }

      await logError(url, {
        ...error,
        ...additionalLogInfo,
      });

      throw new FetchError(`${intro}\n${errorMessage}`, status, true);
    });

async function fetchWithRetries(url: string, init?: RequestInit): Promise<Response> {
  let count = MEDIA_RE_FETCH_RETRY_COUNT;
  while (count > 0) {
    try {
      const response = await fetch(url, init);
      if (response.ok) {
        return response;
      } else {
        await handleError(response, url);
      }
    } catch (error) {
      await handleCatchError(error, url);
    }
    await new Promise((resolve) => setTimeout(resolve, MEDIA_RE_FETCH_DELAY));
    count -= 1;
  }

  throw new Error(`Unable to get data even after ${MEDIA_RE_FETCH_RETRY_COUNT} retries.`);
}

const doFetchAwsBlob = async (awsUrl: string): Promise<string> =>
  await fetchWithRetries(awsUrl)
    .then(async (response) => {
      if (response.ok) {
        return await response.blob().then(async (blob) => URL.createObjectURL(blob));
      } else {
        return await handleError(response, awsUrl);
      }
    })
    .catch(async (ex) => {
      await handleCatchError(ex, awsUrl);
      return await Promise.reject(ex);
    });

const doFetch = async <T>(url: string, init?: RequestInit): Promise<T> =>
  await fetch(url, {
    redirect: 'manual',
    ...init,
    headers: {
      ...(!!APPLICATION_CONFIGURATION.X_Merck_APIKey && {
        'X-Merck-APIKey': APPLICATION_CONFIGURATION.X_Merck_APIKey,
      }),
      ...(init?.body && typeof init.body === 'string' && { 'Content-Type': 'application/json' }),
      ...init?.headers,
    },
  })
    .then(async (response) => {
      if (response.ok) {
        return await response.text().then((text) => {
          try {
            // allowed empty content from backend or JSON
            return text ? JSON.parse(text) : undefined;
          } catch (err) {
            console.error(url, err);

            return { text };
          }
        });
      } else {
        await handleError(response, url, init);
      }
    })
    .catch(async (ex) => {
      await handleCatchError(ex, url);
      throw ex;
    });

const fetchJson = async <T>(url: string): Promise<T> =>
  await doFetch(url, {
    method: 'GET',
  });

const putFileNoAuth = async (url: string, file: File, init?: RequestInit): Promise<any> => {
  const blob = new Blob([file]);

  const logFileInfo = {
    fileName: file.name,
    fileSize: file.size,
    fileType: file.type,
  };

  return await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': file.type,
      'Content-Disposition': `attachment; filename="${file.name}"`,
    },
    body: blob,
    ...init,
  })
    .then(async (response) => {
      if (!response.ok) {
        await handleError(response, url, init, logFileInfo);
      }
      return response;
    })
    .catch(async (ex) => {
      await handleCatchError(ex, url, logFileInfo);
      return await Promise.reject(ex);
    });
};

const deleteJson = async <T>(url: string, body?: any): Promise<T> =>
  await doFetch(url, {
    method: 'DELETE',
    body: body && JSON.stringify(body),
  });

const postJSON = async <T>(url: string, body?: any): Promise<T> => {
  return await doFetch(url, {
    method: 'POST',
    body: body && JSON.stringify(body),
  });
};

const putJson = async <T>(url: string, body?: any): Promise<T> =>
  await doFetch(url, {
    method: 'PUT',
    body: body && JSON.stringify(body),
  });

const fetchMedia = async (awsUrl: string): Promise<any> => await doFetchAwsBlob(awsUrl);

const logAnalytics = async (body: AnalyticsDto): Promise<void> => {
  await postJSON(urlsService.analytics(), body);
};

const fetchCountries = async (): Promise<CountryLocaleDto[]> => await fetchJson(urlsService.countries());

const fetchLanguageSelectionTranslations = async (): Promise<LanguageSelectionTranslationsDto> =>
  await fetchJson(urlsService.fetchLanguageSelectionTranslations());

const fetchCountry = async (countryCode: string): Promise<CountryLocaleDto> =>
  await fetchJson(urlsService.country(countryCode));

const createNewSubmission = async (caseId: string): Promise<CasePublicDto> =>
  await postJSON(urlsService.submissions(caseId));

const startSubmission = async (caseId: string, submissionId: string, localeCode: string): Promise<CasePublicDto> =>
  await postJSON(urlsService.submissionStart(caseId, submissionId, localeCode));

const getUploadMediaDetails = async (
  caseId: string,
  submissionId: string,
  originalStepNumber: number,
  mediaType: MediaTypes,
  fileExtension: string,
): Promise<MediaPutTuple> =>
  await fetchJson(urlsService.uploadMediaDetails(caseId, submissionId, originalStepNumber, mediaType, fileExtension));

const deleteMedia = async (
  caseId: string,
  submissionId: string,
  originalStepNumber: number,
  mediaType: MediaTypes,
  s3Key: string,
  isUploadFailed: boolean,
): Promise<void> => {
  await deleteJson(urlsService.deleteMedia(caseId, submissionId, originalStepNumber, mediaType, s3Key, isUploadFailed));
};

const reportMedia = async (caseId: string): Promise<void> => {
  await postJSON(urlsService.reportMedia(caseId));
};

const getFaq = async (caseId: string): Promise<FaqWithTranslationsDto> => await fetchJson(urlsService.faq(caseId));

const submit = async (caseId: string, submissionId: string): Promise<void> => {
  await putJson(urlsService.submit(caseId, submissionId));
};

const fetchTranslationsByDpocCaseId = async (dpocCaseId: string): Promise<PublicTranslationDto> =>
  await fetchJson(urlsService.translationsByDpocCaseId(dpocCaseId));

const fetchTranslationsByCountryCode = async (countryCode: string): Promise<PublicTranslationDto> =>
  await fetchJson(urlsService.translationsByCountryCode(countryCode));

const fetchService = {
  fetchCountries,
  fetchLanguageSelectionTranslations,
  fetchMedia,
  fetchCountry,
  putFileNoAuth,
  logAnalytics,
  createNewSubmission,
  startSubmission,
  getUploadMediaDetails,
  deleteMedia,
  reportMedia,
  getFaq,
  submit,
  fetchTranslationsByDpocCaseId,
  fetchTranslationsByCountryCode,
};

export default fetchService;
