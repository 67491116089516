import React, { type FC } from 'react';
import { PictureOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import './MediaPlaceholder.scss';

interface MediaPlaceholderProps {
  large?: boolean;
  medium?: boolean;
  borderless?: boolean;
}

const MediaPlaceholder: FC<MediaPlaceholderProps> = ({ large, medium, borderless }) => (
  <div
    className={classNames('MediaPlaceholder', {
      'MediaPlaceholder--large': large,
      'MediaPlaceholder--medium': medium,
      'MediaPlaceholder--borderless': borderless,
    })}
  >
    <PictureOutlined />
  </div>
);

export default MediaPlaceholder;
