import React, { type FC } from 'react';
import { Trans } from 'react-i18next';
import { usePassiveGetCaseDetail } from '../../redux/caseDetail/caseDetailHooks';

import './MailToDpoc.scss';
import { useAppSelector } from '../../redux/hooks';
import { getDpocEmail } from '../../redux/help/helpSlice';
import DpocLink from './DpocLink';

const MailToDpoc: FC = () => {
  const [caseDetail] = usePassiveGetCaseDetail();

  const helpDpocEmail = useAppSelector(getDpocEmail);

  const dpocEmail = helpDpocEmail ?? caseDetail?.country.dpocSharedEmail;

  if (!dpocEmail) {
    return null;
  }

  return (
    <div className="MailToDpoc">
      <Trans
        i18nKey="faq-write-to-dpoc"
        values={{ contactEmail: dpocEmail }}
        components={{
          a: <DpocLink dpocEmail={dpocEmail} caseDetail={caseDetail} />,
        }}
      />
    </div>
  );
};

export default MailToDpoc;
