import React, { type FC } from 'react';

import MediaElement from '../common/MediaElement';
import { type MediaData } from '../../types/app';
import MediaPlaceholder from './MediaPlaceholder';
import { MediaTypes } from '../../types/constants';

import './UploadedMediaSummaryMedia.scss';
import fileStoreService from '../../services/fileStoreService';

interface UploadedMediaSummaryMediaProps {
  mediaType: MediaTypes;
  stepMedia: MediaData[];
}

const UploadedMediaSummaryMedia: FC<UploadedMediaSummaryMediaProps> = ({ mediaType, stepMedia }) => {
  const photosPlaceholders = new Array(5).fill({});
  const videoPlaceholders = new Array(2).fill({});

  return (
    <div className="UploadedMediaSummaryMedia">
      {stepMedia.length
        ? stepMedia.map((media, index) => {
            const fileInfo = fileStoreService.tryGetFile(media.fileStoreKey);

            return fileInfo ? (
              <MediaElement
                className="UploadedMediaSummaryHeader__element"
                key={index}
                link={fileInfo.content}
                isVideo={media.mediaType === MediaTypes.VIDEO}
              />
            ) : (
              <MediaPlaceholder />
            );
          })
        : mediaType === MediaTypes.IMAGE
          ? photosPlaceholders.map((_, index) => <MediaPlaceholder key={index} />)
          : videoPlaceholders.map((_, index) => <MediaPlaceholder key={index} />)}
    </div>
  );
};

export default UploadedMediaSummaryMedia;
