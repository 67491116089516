import { useCallback } from 'react';
import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from '../hooks';
import { getIsSubmitting, getMedia, uploadActions } from './uploadSlice';
import { type MediaData } from '../../types/app';
import { type MediaTypes } from '../../types/constants';
import convertorService from '../../services/convertorService';
import fileStoreService from '../../services/fileStoreService';
import { useStepInstructions } from '../caseDetail/caseDetailHooks';

type SubmitAction = () => void;
type DeleteAction = () => void;
type GetStepMediaAction = (stepNumber: number) => MediaData[];
type GetAllMediaAction = () => MediaData[];

export const useGetStepMedia: GetStepMediaAction = (stepNumber) => {
  const media = useAppSelector(getMedia);
  return media[stepNumber] || [];
};

export const useGetAllMedia: GetAllMediaAction = () => {
  const media = useAppSelector(getMedia);
  return Array.prototype.concat.apply([], Object.values(media));
};

export const useUploadFile: (stepNumber: number, mediaType: MediaTypes) => (file: File) => Promise<any> = (
  stepNumber,
  mediaType,
) => {
  const dispatch = useAppDispatch();
  const stepInstruction = useStepInstructions(stepNumber);

  const uploadFile: (file: File) => Promise<any> = useCallback(
    async (file) => {
      const fileStoreKey = fileStoreService.createNewKey();
      dispatch(uploadActions.preUploadFile({ fileStoreKey, stepNumber, mediaType }));
      await convertorService.tryConvertHeicFile(file).then((convertedFile) => {
        const reader = new FileReader();
        reader.readAsDataURL(convertedFile);
        reader.onload = (e) => {
          const fileContent = e.target?.result as string;

          fileStoreService.storeFile(convertedFile, fileContent, fileStoreKey);

          if (stepInstruction) {
            dispatch(
              uploadActions.uploadFile({
                fileStoreKey,
                mediaType,
                stepNumber,
                originalStepNumber: stepInstruction.stepNumber,
              }),
            );
          }
        };
      });
    },
    [dispatch, mediaType, stepInstruction, stepNumber],
  );

  return uploadFile;
};

export const useSubmit: () => [SubmitAction, boolean] = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isSubmitting = useAppSelector(getIsSubmitting);

  const submit = useCallback(() => {
    dispatch(uploadActions.submit(navigate));
  }, [dispatch, navigate]);

  return [submit, isSubmitting];
};

export const useDeleteImage: (stepNumber: number, media: MediaData) => DeleteAction = (stepNumber, media) => {
  const dispatch = useAppDispatch();
  const stepInstruction = useStepInstructions(stepNumber);

  const deleteFile: DeleteAction = () => {
    if (stepInstruction) {
      dispatch(
        uploadActions.deleteMedia({
          fileStoreKey: media.fileStoreKey,
          stepNumber,
          originalStepNumber: stepInstruction.stepNumber,
          mediaType: media.mediaType,
          s3Key: media.s3Key,
          uploadState: media.uploadState,
        }),
      );
      fileStoreService.releaseFile(media.fileStoreKey);
    }
  };

  return deleteFile;
};
