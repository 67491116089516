import heic2any from 'heic2any';

const HEIC_TO_FILE_NAME_EXTENSION = '.jpeg';
const HEIC_TO_FILE_FORMAT = 'image/jpeg';

const convertFileNameExtension = (fileName: string): string => {
  return fileName.substring(fileName.lastIndexOf('.')) + HEIC_TO_FILE_NAME_EXTENSION;
};

// We have issues to convert HEIC on the BE. So we are doing it here on FE (PQCPIMS-1107).
const tryConvertHeicFile = async (file: File): Promise<File> => {
  try {
    return await heic2any({ blob: file, toType: HEIC_TO_FILE_FORMAT, quality: 1 })
      .then((result) => new File(Array.isArray(result) ? result : [result], convertFileNameExtension(file.name)))
      // If not HEIC, return original file
      .catch(() => file);
  } catch (e) {
    console.error(e);
  }
  return file;
};

const convertorService = { tryConvertHeicFile };

export default convertorService;
