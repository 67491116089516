import React, { type FC } from 'react';
import { ExclamationOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';

import './UploadErrorIcon.scss';

interface UploadErrorIconProps {
  className?: string;
}

const UploadErrorIcon: FC<UploadErrorIconProps> = ({ className }) => (
  <Button
    className={classNames('UploadErrorIcon', { [className ?? '']: className })}
    danger
    type="primary"
    icon={<ExclamationOutlined />}
    shape="circle"
  />
);

export default UploadErrorIcon;
