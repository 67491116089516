import React, { type FC } from 'react';
import errorDog from '../../images/errorDog.svg';

import './ErrorBoundaryContent.scss';

const ErrorBoundaryContent: FC = () => {
  return (
    <div className="ErrorBoundaryContent">
      <h4 className="ErrorBoundaryContent__title">Product Complaint Photo App</h4>
      <img className="ErrorBoundaryContent__image" alt="Application Error" src={errorDog} />
      <div className="ErrorBoundaryContent__header">Sorry, something went wrong.</div>
      <div className="ErrorBoundaryContent__description">
        If you are unable to upload your photos to the app, please contact your local Designated Point of Contact (DPOC)
        (listed in the package insert) for further instruction.
      </div>
    </div>
  );
};

export default ErrorBoundaryContent;
