import { call, type Effect, type ForkEffect, put, select, takeEvery } from 'redux-saga/effects';
import { countryActions, type FetchCountryPayload } from './countrySlice';
import { type PayloadAction } from '@reduxjs/toolkit';
import fetchService, { type FetchError } from '../../services/fetchService';
import { notification } from 'antd';
import { type CountryLocaleDto } from '../../types/dtos';
import localizationService from '../../services/localizationService';
import i18n from '../../i18n';
import { getLanguageUserSelected } from '../language/languageSlice';

function* watchFetchCountry(action: PayloadAction<FetchCountryPayload>): Generator<Effect, void, CountryLocaleDto> {
  const { countryCode } = action.payload;
  try {
    const country = yield call(fetchService.fetchCountry, countryCode);
    const languageUserSelected = yield select(getLanguageUserSelected);
    yield put(countryActions.fetchCountryFinished(country));
    const currentLanguageCode = i18n.language;

    if (!languageUserSelected || country.allowedLocales.every((locale) => locale.localeCode !== currentLanguageCode)) {
      yield call(localizationService.changeLanguage, country.defaultLocaleCode);
    }
  } catch (e) {
    if ((e as FetchError).status === 404) {
      notification.error({
        message: 'Country not found',
        description: `Unable to load country details for country with code '${countryCode ?? ''}'`,
      });
    }
    yield put(countryActions.fetchCountryFailed());
  }
}

export function* countrySagas(): Generator<ForkEffect, void> {
  yield takeEvery(countryActions.fetchCountry, watchFetchCountry);
}

export default countrySagas;
