import React, { type FC } from 'react';
import { useTranslation } from 'react-i18next';

import './AgreementRequiredError.scss';

const AgreementRequiredError: FC = () => {
  const { t } = useTranslation();

  return <div className="AgreementRequiredError">{t('CaseEnter_agreedRequired')}</div>;
};

export default AgreementRequiredError;
