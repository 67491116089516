import React from 'react';
import i18n from '../../i18n';
import { notification } from 'antd';
import { call, delay, type Effect, type ForkEffect, put, select, take, takeEvery } from 'redux-saga/effects';
import { type NavigateFunction } from 'react-router';
import { type PayloadAction } from '@reduxjs/toolkit';

import { type DeleteMediaPayload, getMedia, uploadActions, type UploadFilePayload } from './uploadSlice';
import fetchService from '../../services/fetchService';
import fileStoreService from '../../services/fileStoreService';
import { getCaseDetail } from '../caseDetail/caseDetailSlice';
import { type CasePublicDto, type MediaPutTuple } from '../../types/dtos';
import { type MediaData, UploadStates } from '../../types/app';
import { routes } from '../../router';
import { CONFIRMATION_PAGE_ID, type MediaTypes } from '../../types/constants';
import UploadErrorDescription from './UploadErrorDescription';
import UploadErrorMessage from './UploadErrorMessage';
import { UploadErrorIcon } from '../../components';

function* doUploadMedia(
  caseDetail: CasePublicDto,
  stepNumber: number,
  originalStepNumber: number,
  fileStoreKey: string,
  mediaType: MediaTypes,
): Generator<Effect, void, any> {
  try {
    const { file } = fileStoreService.getFile(fileStoreKey);

    const extension = file?.name?.split('.').pop() ?? '';

    const mediaTuple: MediaPutTuple = yield call(
      fetchService.getUploadMediaDetails,
      caseDetail.dpocCaseId,
      caseDetail.submission.id,
      originalStepNumber,
      mediaType,
      extension,
    );

    yield put(
      uploadActions.uploadFilePreSignedUrl({
        fileStoreKey,
        stepNumber,
        url: mediaTuple.mediaPutUrl,
        s3Key: mediaTuple.mediaS3Key,
      }),
    );

    yield call(fetchService.putFileNoAuth, mediaTuple.mediaPutUrl, file);

    yield put(
      uploadActions.uploadFileFinished({
        fileStoreKey,
        stepNumber,
      }),
    );
  } catch {
    const totalSteps = caseDetail.submission.stepInstructions[i18n.language].length;

    notification.error({
      message: React.createElement(UploadErrorMessage, { stepNumber, totalSteps }),
      description: React.createElement(UploadErrorDescription, { caseDetail }),
      icon: React.createElement(UploadErrorIcon),
      duration: 0,
    });
    yield put(uploadActions.uploadFileFailed({ stepNumber, fileStoreKey }));
  }
}

function* watchUploadFile(action: PayloadAction<UploadFilePayload>): Generator<Effect, void, CasePublicDto> {
  const { stepNumber, originalStepNumber, fileStoreKey, mediaType } = action.payload;
  const caseDetail = yield select(getCaseDetail);

  yield call(doUploadMedia, caseDetail, stepNumber, originalStepNumber, fileStoreKey, mediaType);
}

function* watchDeleteMedia(action: PayloadAction<DeleteMediaPayload>): Generator<Effect, void, CasePublicDto> {
  try {
    const { originalStepNumber, s3Key, mediaType, uploadState } = action.payload;

    if (!s3Key) {
      if (uploadState !== UploadStates.UploadFailed) {
        console.error(
          `watchDeleteMedia: Missing s3key for media deletion. The media is not in UploadFailed state. It should have s3key.`,
        );
      }
      yield put(uploadActions.deleteMediaFailed());
      return;
    }

    const caseDetail = yield select(getCaseDetail);
    const isUploadFailed = uploadState === UploadStates.UploadFailed;

    yield call(
      fetchService.deleteMedia,
      caseDetail.dpocCaseId,
      caseDetail.submission.id,
      originalStepNumber,
      mediaType,
      s3Key,
      isUploadFailed,
    );
    yield put(uploadActions.deleteMediaFinished());
  } catch {
    yield put(uploadActions.deleteMediaFailed());
  }
}

function* watchSubmit(action: PayloadAction<NavigateFunction>): Generator<Effect, void, any> {
  const caseDetail: CasePublicDto = yield select(getCaseDetail);
  try {
    let media: Record<number, MediaData[]> = yield select(getMedia);

    while (
      Object.values(media).some((mediaArray) =>
        mediaArray.some(
          ({ uploadState }) => uploadState === UploadStates.Uploading || uploadState === UploadStates.PreUploading,
        ),
      )
    ) {
      yield take();
      media = yield select(getMedia);
    }

    yield call(fetchService.submit, caseDetail.dpocCaseId, caseDetail.submission.id);
    action.payload(routes.confirmation(caseDetail.dpocCaseId));

    yield delay(0);

    // PQCPIMS-648: On some phones, uploadActions.submitFinished() is dispatched before the page is re-rendered with Confirmation page.
    // That causes redirect to landing page, because we are still on Submit Page and submission is switched to finished  - submit page requires started not finished submission
    while (!window.document.getElementById(CONFIRMATION_PAGE_ID)) {
      yield delay(50);
    }

    yield put(uploadActions.submitFinished());
  } catch {
    yield put(uploadActions.submitFailed());
  }
}

export function* watchUploadSagas(): Generator<ForkEffect, void> {
  yield takeEvery(uploadActions.uploadFile, watchUploadFile);
  yield takeEvery(uploadActions.deleteMedia, watchDeleteMedia);
  yield takeEvery(uploadActions.submit, watchSubmit);
}

const uploadSagas = watchUploadSagas;

export default uploadSagas;
