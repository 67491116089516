import React, { type FC } from 'react';
import { Trans } from 'react-i18next';
import { type CasePublicDto } from '../../types/dtos';

import './UploadErrorDescription.scss';
import DpocLink from '../../components/Help/DpocLink';

interface UploadErrorDescriptionProps {
  caseDetail: CasePublicDto;
}

const UploadErrorDescription: FC<UploadErrorDescriptionProps> = ({ caseDetail }) => {
  const dpocSharedEmail = caseDetail.country.dpocSharedEmail;

  return (
    <span className="UploadErrorDescription">
      <Trans
        i18nKey="Upload_failedMessage"
        values={{ contactEmail: dpocSharedEmail }}
        components={{
          // eslint-disable-next-line jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid
          a: <DpocLink dpocEmail={dpocSharedEmail} caseDetail={caseDetail} />,
        }}
      />
    </span>
  );
};

export default UploadErrorDescription;
